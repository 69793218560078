@import 'shared';

.Gallery {
  display: grid;
  gap: px(16);

  @include media-tablet {
    grid-template-columns: minmax(0, 0.7fr) minmax(0, 1fr);
  }

  .image {
    margin-bottom: px(16);
  }

  .caption {
    color: $darker-gold;
    @include typography-label;
  }
}
